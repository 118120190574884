









































































































































































































































































































































































































































































import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import BillChat from "@/components/Input/BillChat.vue";
import BillInfo from "@/components/Layout/Client/BillInfo.vue";
import { Notification } from "@/models/notification.interface";
import { UserValidations } from "@/mixins/user-validations";
import { StyleCheck } from "@/mixins/style-check";
import { TermTypes } from "@/mixins/term-types";

@Component({
  components: {
    Loader,
    Empty,
    BillChat,
    BillInfo,
  },
})
export default class UserBills extends mixins(
  Navigation,
  UserValidations,
  CustomDate,
  StyleCheck,
  TermTypes
) {
  search = "";
  loader = false;
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private getAllPaymentsByBill(bill) {
    let payments: Array<any> = [];

    bill.payments_balance.forEach((payment: any) => {
      payments.push(payment);
    });

    bill.payments_cryptocurrency.forEach((payment: any) => {
      payments.push(payment);
    });

    bill.payments_traditional.forEach((payment: any) => {
      payments.push(payment);
    });

    payments.sort(function (a, b) {
      let c = new Date(a.date).getTime();
      let d = new Date(b.date).getTime();
      return c - d;
    });

    return payments;
  }

  private getTotalPaidForBill(bill) {
    let currentTotal = 0;

    bill.payments_balance.forEach((payment: any) => {
      currentTotal = currentTotal + payment.amount;
    });

    bill.payments_cryptocurrency.forEach((payment: any) => {
      if (
        payment.status &&
        !(payment.status.name == this.$constants.STATUS.CANCELED)
      ) {
        currentTotal = currentTotal + payment.amount;
      }
    });

    bill.payments_traditional.forEach((payment: any) => {
      if (
        payment.status &&
        !(payment.status.name == this.$constants.STATUS.CANCELED)
      ) {
        currentTotal = currentTotal + payment.amount;
      }
    });

    return currentTotal;
  }

  private async resumeCheckout(bill) {
    await this.$store.dispatch("checkout/setCheckoutInformation", {
      product_id: bill.products_bill[0].product.id,
      previous_route: this.$route.fullPath,
      on_checkout: true,
      resume: true,
      bill_id: bill.id,
      bill_payments: this.getAllPaymentsByBill(bill),
      bill_current_paid: this.getTotalPaidForBill(bill),
    });

    this.navigate("/investment/checkout");
  }

  private changePage() {
    this.navigate("/user/shopping/" + this.pagination.page);
  }

  private async getBillsByUser(
    page: string | number,
    size: number,
    bill_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("bill/getUserBills", {
        page,
        size,
        user_id: this.userLogged.id,
        operation: "minus",
        bill_id: bill_id,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.bill-error-get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;

    await this.getBillsByUser(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;

    await this.$store
      .dispatch("users/getUserBalanceById", this.userID)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userBalance", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    window.scrollTo(0, 0);
  }

  private get bills() {
    let response = this.$store.getters["bill/getUserBills"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.bills) {
      return response?.bills;
    } else {
      return [];
    }
  }

  private get totalPages() {
    const total =
      this.pagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private get userID(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.id;
    } else {
      return {};
    }
  }

  private get userBalance(): any {
    return this.$store.getters["users/getUserBalance"];
  }

  @Watch("$route.params", { deep: true })
  private async handler() {
    this.loader = true;
    await this.getBillsByUser(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }

  private investmentParticipants(total, available) {
    const current = total - available;
    return (current * 100) / total;
  }

  //Search by ID
  @Watch("search")
  private async searchReturnByID() {
    if (this.search == null) {
      this.search = "";
    }

    await this.getBillsByUser(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
  }
}
